import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/spindle.ameba.design/spindle.ameba.design/src/components/Layout.tsx";
import SEO from '../../../components/SEO';
import PanelNav from '../../../components/PanelNav';
import ContentsList from '../../../components/ContentsList';
import PageTitle from '../../../components/PageTitle';
import Flash from '@openameba/spindle-ui/Icon/Flash';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEO title="パフォーマンス - 原則" mdxType="SEO" />
    <PageTitle title="パフォーマンス" enTitle="Performance" mdxType="PageTitle" />
    <p>{`Amebaにとってパフォーマンスは「ユーザーが望むこと」を叶えるために重要です。興味のある記事はできるだけ早く読みたいと思いますし、書いた記事が必要な人に届いて欲しいと望むでしょう。サービスを早く安定して提供することで、結果的により多くの人に利用してもらえます。`}</p>
    <p>{`パフォーマンスはまた、ユーザー体験を向上させます。Amebaでは、以下の状態を目指しています。`}</p>
    <ul>
      <li parentName="ul">{`素早く表示され、安定して動作し、予想しない挙動をしないこと`}</li>
      <li parentName="ul">{`読みたいページや重要な要素ができるだけ早く表示されること`}</li>
      <li parentName="ul">{`スクロールやタップなど利用者の操作に安定して返答できること`}</li>
      <li parentName="ul">{`突然要素が消えたりして利用者が不快に感じないこと`}</li>
    </ul>
    <p>{`パフォーマンスの向上は、ビジネス指標にも効果があると言われています。`}<a parentName="p" {...{
        "href": "https://www.akamai.com/uk/en/about/news/press/2017-press/akamai-releases-spring-2017-state-of-online-retail-performance-report.jsp"
      }}>{`2017年のAkamaiによる調査`}</a>{`では読み込み時間が100ミリ秒遅延すると、そのたびにコンバージョン率が7%下がるという結果が出ています。また、`}<a parentName="p" {...{
        "href": "https://developers.cyberagent.co.jp/blog/archives/636/"
      }}>{`2016年のAmebaブログシステムリニューアル`}</a>{`ではレンダリング速度を40%短縮したことで、ページビューが50%向上しました。`}</p>
    <ContentsList list={[{
      url: "/principles/performance/checklist/",
      title: "チェックリスト",
      enTitle: "Checklist",
      summary: "パフォーマンスを改善するため、考慮に入れたいことをチェックリスト形式で紹介しています。"
    }, {
      url: "/principles/performance/how-to/",
      title: "改善の進め方",
      enTitle: "Optimization",
      summary: "パフォーマンスを改善するための進め方を紹介しています。"
    }, {
      url: "/principles/performance/resources/",
      title: "関連資料",
      enTitle: "Resources",
      summary: "パフォーマンスに関する関連資料を掲載しています。"
    }]} mdxType="ContentsList"></ContentsList>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      